














































































































.businessTravel {
    .gulationsContentPar {
        margin-top: 0.3rem;
        margin-bottom: .7rem;

        @media (max-width:767px) {
            margin-top: 0.3rem;
        }
    }

    .gulationsContent {
        .contentBox {
            margin-bottom: 0.7rem;
        }

        .title1 {
            color: #ff1932;
            font-size: 0.22rem;
            margin: 0.3rem 0;
            font-weight: bold;
			@media (max-width:767px) {
			    font-size: 0.38rem 
			}
        }

        .title2 {
            color: #ffffff;
            font-size: 0.3rem;
            background: #000000;
            padding: 0.2rem 0.3rem;
            margin-bottom: 0.4rem;
        }

        .title3 {
            color: #ffffff;
            font-size: 0.24rem;
            background: #ff1932;
            padding: 0.1rem 0.2rem 0.1rem 0;
            margin: 0 0 0.1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width:767px) {
                font-size: 0.4rem;
            }

            .icon {
                cursor: pointer;
                transition: transform .2s;

                @media (max-width:767px) {
                    font-size: 24px !important;
                }

                &.up {
                    transform: rotate(180deg);
                }
            }
        }

        .title4 {
            color: #333333;
            font-size: 0.3rem;
            background: #eeeeee;
            padding: 0.2rem 0.3rem;
            margin: 0.7rem 0;
        }

        .title5 {
            color: #ffffff;
            font-size: 0.3rem;
            background: #ffb9a0;
            padding: 0.2rem 0.3rem;
            margin: 0.7rem 0;
        }

        .content {
            color: #333333;
            font-size: 0.2rem;
            line-height: 2;
            margin-bottom: 0.1rem;
            text-align: justify;
			@media (max-width:767px) {
			    font-size: 0.38rem 
			}
        }

        .downLoad {
            .wCenter {
                display: flex;
                flex-wrap: wrap;

                .fileType {
                    display: inline-block;
                    background-color: #aa1b27;
                    width: 1rem;
                    height: 1rem;
                    font-size: .3rem;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media (max-width: 767px) {
                        font-size: .26rem;
                    }
                }

                @media (max-width: 767px) {
                    padding: 0;
                }
            }

            .downLoadItem {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 3rem;
                height: 3rem;
                border: 0.01rem solid #eeeeee;
                margin: 0.2rem 0.33rem 0 0;

                &:nth-child(4n) {
                    margin-right: 0;
                }

                @media (max-width: 767px) {
                    $w: 31%;
                   // $gutter: (100% - $w * 3)/2;
					$gutter: 3.5%;
                    width: $w;
                    margin: 0 $gutter $gutter 0;

                    &:nth-child(3n) {
                        margin-right: 0;
                    }

                    &:nth-child(4n) {
                        margin-right: $gutter;
                    }
                }

                img {
                    width: 1rem;
                }

                .title {
                    color: #333333;
                    font-size: 0.24rem;
                    margin: 0.26rem 0 0.44rem 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 90%;
                    text-align: center;
					@media (max-width:767px) {
					    font-size: 0.38rem 
					}
                }

                .btn {
                    width: 2rem;
                    height: 0.5rem;
                    line-height: 0.5rem;
                    background: #333333;
                    color: #ffffff;
                    border-radius: 0.04rem;
                    text-align: center;
                    font-size: 0.24rem;
					@media (max-width:767px) {
					        height: 0.7rem;
					        line-height: 0.7rem;
							font-size: 0.32rem;
					}
                    a {
                        color: inherit;
                    }
                }
            }
        }
    }
}
